<template>
  <div class="elv-account-platform-container">
    <el-radio-group v-model="platformTab" class="elv-account-platform-tabs" @change="onChangePlatformTabs">
      <el-radio-button
        value="CHAIN"
        :class="{ 'not-has-line': entityStore.custodyList?.length || entityStore.paymentList?.length }"
        >{{ t('common.onChain') }}</el-radio-button
      >
      <el-radio-button
        :class="{ 'not-has-line': entityStore.custodyList?.length && entityStore.paymentList?.length }"
        value="EXCHANGE"
        >{{ t('common.exchange') }}</el-radio-button
      >
      <el-radio-button value="BANK">{{ t('common.bank') }}</el-radio-button>
      <el-radio-button v-if="entityStore.custodyList?.length" value="CUSTODY">{{
        t('common.custody')
      }}</el-radio-button>
      <el-radio-button v-if="entityStore.paymentList?.length" value="PAYMENT">{{
        t('common.payment')
      }}</el-radio-button>
    </el-radio-group>
    <el-scrollbar ref="platformListRef" height="330" class="elv-account-platform-view">
      <div
        v-for="item in dataList"
        :key="item.platformId"
        class="elv-account-platform-item"
        :class="{
          'is-selected': props.platformData?.platformId === item?.platformId,
          'is-disabled': !item?.csvDriverTemplateList?.length && item.collectorDriver === 'NONE'
        }"
        @click="onSelectedPlatform(item)"
      >
        <img :src="item.bigLogo ?? item.logo" :alt="item.name" :style="{ width: platformLogoWidth(item.name) }" />
        <template v-if="!item?.csvDriverTemplateList?.length && item.collectorDriver === 'NONE'">
          <div class="elv-account-platform-item__supported is-comingSoon">
            {{ t('common.comingSoon') }}
          </div>
        </template>
        <template v-else>
          <ul class="elv-account-platform-item__supported-list">
            <li
              v-for="(platform, i) in platformType(item).value"
              :key="i"
              class="elv-account-platform-item__supported"
              :class="[`is-${platform.type.toLocaleLowerCase()}`]"
            >
              {{ platform.label }}
            </li>
          </ul>
        </template>
        <div v-show="item?.isBeta" class="elv-account-platform-item-beta">
          {{ t('common.beta') }}
        </div>
      </div>
    </el-scrollbar>

    <div class="el-dialog__footer">
      <elv-button
        type="primary"
        round
        height="44"
        width="113"
        :disabled="isEmpty(props.platformData)"
        class="elv-accounts--dialog-footer__button"
        @click="onClickContinue"
        >{{ t('button.continue') }}</elv-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { filter, isEmpty } from 'lodash-es'
import { PlatformType } from '#/EntityTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'

const props = defineProps({
  platformData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const platformTab = ref('CHAIN')
const platformListRef = useTemplateRef('platformListRef')

const platformLogoWidth = computed(() => {
  return (name: string) => {
    let width = '150px'
    switch (name) {
      case 'Huobi':
        width = '90px'
        break
      case 'BCB Blinc':
        width = '120px'
        break
      default:
        break
    }
    return width
  }
})

const dataList: any = computed(() => {
  let platformList: PlatformType[] = []
  switch (platformTab.value) {
    case 'CHAIN':
      platformList = entityStore.chainList
      break
    case 'BANK':
      platformList = entityStore.bankList
      break
    case 'EXCHANGE':
      platformList = entityStore.exchangeList
      break
    case 'CUSTODY':
      platformList = entityStore.custodyList
      break
    case 'PAYMENT':
      platformList = entityStore.paymentList
      break
    default:
      break
  }
  return platformTab.value === 'BANK'
    ? platformList
    : filter(platformList, (item: PlatformType) => {
        return item.bigLogo && item.slug !== 'other'
      })
})

const platformType = useComputedHook((row: any) => {
  const type: any = []
  if (row.collectorDriver !== 'NONE') {
    type.push({ type: 'api', label: 'API' })
  }
  if (row?.csvDriverTemplateList?.length > 0) {
    type.push({ type: 'csv', label: 'CSV' })
  }
  return type
})

const emit = defineEmits(['onClickContinue', 'onSelectedPlatform'])

const onSelectedPlatform = (platform: PlatformType) => {
  if (platform.csvDriverTemplateList?.length > 0 || platform.collectorDriver !== 'NONE') {
    emit('onSelectedPlatform', platform)
  }
}

const onChangePlatformTabs = () => {
  platformListRef.value?.scrollTo({ top: 0, behavior: 'smooth' })
}

const onClickContinue = () => {
  emit('onClickContinue')
}
</script>

<style lang="scss">
.elv-account-platform-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-radio-group.elv-account-platform-tabs {
  border-radius: 30px;
  margin-bottom: 16px;

  .el-radio-button {
    &:nth-of-type(1) {
      .el-radio-button__inner {
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        padding-left: 12px;
      }
    }

    &.not-has-line .el-radio-button__inner {
      border-right: 0px !important;
    }

    &:nth-last-of-type(2) {
      .el-radio-button__inner {
        border-left: 0px !important;
        border-right: 0px !important;
      }
    }

    &:nth-last-of-type(1) {
      .el-radio-button__inner {
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        padding-right: 12px;
      }
    }

    .el-radio-button__inner {
      padding: 7px 8px;
      box-sizing: border-box;
      color: #838d95;
      background-color: #f9fafb;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #dde1e6;
      box-shadow: none !important;
    }

    &:not(.is-active):hover {
      .el-radio-button__inner {
        color: #636b75;
      }
    }

    &.is-active {
      .el-radio-button__inner {
        background-color: #fff;
        color: #0e0f11;
        border: 1px solid #dde1e6;
      }
    }
  }
}

.elv-account-platform-view {
  width: 100%;
  // width: 572px;

  .el-scrollbar__view {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0 24px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 12px 11px;
    flex-shrink: 0;
    flex-wrap: wrap;
  }

  .elv-account-platform-item {
    display: flex;
    flex-direction: column;
    width: 182px;
    height: 80px;
    padding: 0px 23px 7px;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #edf0f3;
    background: #fff;
    transition: all 0.1s;
    cursor: pointer;
    position: relative;

    img {
      width: 150px;
      display: block;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .elv-account-platform-item__supported-list {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    .elv-account-platform-item__supported {
      display: flex;
      padding: 2px 4px;
      align-items: flex-start;
      gap: 10px;
      margin-top: 8.5px;
      border-radius: 3px;
      border: 1px solid #dde1e6;
      color: #d0d4d9;
      font-family: 'Plus Jakarta Sans';
      font-size: 7px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;

      &.is-chain {
        color: #5e85eb;
        border-color: #90abf0;
      }

      &.is-csv {
        color: #749a01;
        border-color: #83ae00;
      }

      &.is-api {
        color: #c97928;
        border-color: #e68b2f;
      }
    }

    .elv-account-platform-item-beta {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 30px;
      height: 14px;
      background-color: #edf0f3;
      border-radius: 0px 3px 0px 3px;
      font-family: 'Plus Jakarta Sans';
      font-size: 8px;
      font-weight: 600;
      color: #838d95;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.is-disabled {
      cursor: not-allowed;
    }

    &.is-selected {
      border: 1px solid #1343bf;
      box-shadow: 0px 0px 0px 2px rgba(23, 83, 235, 0.3);
    }

    &:not(.is-selected, .is-disabled):hover {
      border: 1px solid #5e85eb;
    }
  }
}
</style>
