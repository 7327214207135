<template>
  <el-drawer
    :model-value="isShowViewDrawer"
    :show-close="false"
    modal-class="elv-account-overlay"
    class="elv-account-drawer"
    :before-close="onCheckDrawerStatus"
    :size="450"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-drawer-header__title">
        {{ t('common.account') }}
      </h4>
      <SvgIcon
        name="close"
        width="24"
        height="24"
        class="elv-account-drawer-header__close"
        @click="onCheckDrawerStatus"
      />
    </template>
    <div v-loading="loading">
      <div class="elv-account-drawer-content-header">
        <div class="elv-account-drawer-content-title">
          <div class="elv-account-drawer-content-title__currency">
            <img
              v-if="currentAccountData?.platform?.logo"
              :src="currentAccountData?.platform?.logo"
              :onerror="useDefaultImage"
              :alt="currentAccountData?.platform?.name"
            />
            <img v-else :onerror="useDefaultImage" src="@/assets/img/default-logo.png" alt="logo" />
            <span class="elv-account-drawer-currency__name">{{ currentAccountData?.platform?.name }}</span>
          </div>
          <div class="elv-account-drawer-content-title__operating">
            <el-button link @click="onEditAccount">
              <SvgIcon name="sources-edit" width="14" height="14" />
              {{ t('button.edit') }}
            </el-button>
            <div class="elv-account-drawer-content__delete" @click="onDeleteAccount">
              <SvgIcon name="sources-delete" width="14" height="14" />
            </div>
          </div>
        </div>
        <div class="elv-account-drawer-content-info">
          <div class="elv-account-drawer-content-name">{{ currentAccountData?.name }}</div>
          <div v-if="currentAccountData?.identity" class="elv-account-drawer-content-paymentIdentifier">
            <p>{{ formatInputAddress(currentAccountData?.identity) }}</p>
            <SvgIcon
              v-if="props.drawerData?.platform?.type !== 'MARKET'"
              name="sources-copy"
              width="12"
              height="12"
              @click="onClickedCopy(currentAccountData?.identity)"
            />
          </div>
        </div>
      </div>
      <el-scrollbar max-height="calc(100vh - 219px)" wrap-class="elv-account-drawer-content-body__wrap">
        <div class="elv-account-drawer-balance">
          <div class="elv-account-drawer-balance-title">
            <div class="elv-account-drawer-balance-title-left">{{ t('common.balance') }}</div>
            <div class="elv-account-drawer-balance-title-right">
              <el-tooltip
                v-if="currentProvider"
                effect="dark"
                placement="top"
                popper-class="elv-account-drawer-balance-tips"
                overlay-classname="elv-account-drawer-balance-tips"
                :show-after="500"
              >
                <TextIconSelect
                  v-model="currentProviderId"
                  :disabled="balanceLoading"
                  :options="computedOptions"
                  @change="onSelectChange"
                />
                <template #content>
                  <div class="elv-account-drawer-balance-tips-content">{{ tooltip }}</div>
                </template>
              </el-tooltip>
              <TextIconSelect
                v-else
                v-model="currentProviderId"
                :disabled="balanceLoading"
                :options="computedOptions"
                @change="onSelectChange"
              />
            </div>
          </div>
          <ul
            v-if="currentAccountData?.treasuryBalanceList?.length"
            v-loading="balanceLoading"
            class="elv-account-drawer-balance-list"
          >
            <li
              v-for="(item, index) in currentAccountData?.treasuryBalanceList"
              :key="item.currency"
              class="elv-account-drawer-balance-item"
            >
              <div>
                <img
                  v-if="item?.underlyingCurrency?.logo"
                  :src="item?.underlyingCurrency?.logo"
                  :onerror="useDefaultImage"
                  :alt="item?.underlyingCurrency?.name"
                />
                <div>
                  <div class="elv-account-drawer-balance-item__name">{{ item?.underlyingCurrency?.name }}</div>
                  <div class="elv-account-drawer-balance-item__count">
                    <div>{{ item?.underlyingCurrency?.showSymbol }}</div>
                  </div>
                </div>
              </div>
              <div class="elv-account-drawer-balance-item-operating">
                <div>{{ formatNumber(item.amount, 8) }}</div>
                <el-tooltip
                  v-if="item?.failCount"
                  effect="dark"
                  placement="top"
                  popper-class="elv-report-table-tips"
                  overlay-classname="elv-report-table-tips"
                  :show-after="500"
                >
                  <div class="elv-account-drawer-balance-item-verify">
                    <img src="@/assets/img/reports/table-warning-filled.png" alt="filled" />
                  </div>

                  <template #content>
                    <p>{{ t('message.balanceVerificationFailed') }}</p>
                  </template>
                </el-tooltip>
                <div v-if="currentProvider?.slug === 'calculated'" class="elv-account-drawer-balance-add">
                  <el-popover
                    ref="addBalancePopoverRef"
                    :width="178"
                    trigger="click"
                    placement="bottom-start"
                    popper-class="elv-account-drawer-balance-add-popover"
                    :offset="2"
                  >
                    <template #reference>
                      <div><SvgIcon width="14" height="14" name="more-dian" /></div>
                    </template>
                    <div class="elv-account-balance-type-select__item" @click="onSelectedBalance(item, index)">
                      {{ t('button.addBalance') }}
                    </div>
                  </el-popover>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="elv-account-drawer-balance-empty">
            <SvgIcon name="nominal" width="24" height="24" />
            {{ t('message.accountBalanceEmptyInfo') }}
          </div>
        </div>

        <div
          v-if="!['MEMBER', ''].includes(currentEntityPermission?.role) || currentEntityPermission?.dataSource?.view"
          class="elv-account-drawer-source"
        >
          <div class="elv-account-drawer-source-title">{{ t('report.dataSource') }}</div>
          <ul class="elv-account-drawer-source-list">
            <li
              v-for="item in currentAccountData?.sourceList"
              :key="item.sourceId"
              class="elv-account-drawer-source-item"
            >
              <div>
                <img
                  v-if="
                    item?.provider?.logo &&
                    item?.platform?.type !== 'CSV' &&
                    !['CSV', 'CSV_BALANCE'].includes(item.type)
                  "
                  :src="item?.provider?.logo"
                  :onerror="useDefaultImage"
                  :alt="item?.provider?.name"
                />
                <SvgIcon v-if="['CSV', 'CSV_BALANCE'].includes(item?.type)" name="csv-file" width="24" height="24" />
                <SvgIcon
                  v-else-if="item?.type === 'OPENAPI'"
                  name="API"
                  width="14"
                  height="14"
                  fill="#2F63EB"
                  style="margin: 13px 5px 0 5px"
                />
                <div>
                  <div class="elv-account-drawer-source-item__name">{{ item?.name }}</div>
                  <div
                    v-if="item?.thirdPartyOpenAuthData?.status && item?.thirdPartyOpenAuthData?.status !== 'COMPLETED'"
                    class="elv-account-drawer-source-item__auth"
                  >
                    <SvgIcon name="warning" width="14" height="14" />Sync failed,<span @click="onReAuthenticate(item)"
                      >click to re-authenticate</span
                    >
                  </div>
                  <div class="elv-account-drawer-source-item__count">
                    <div
                      v-if="
                        ['HISTORY_BRONEZE_DOING', 'HISTORY_SILVER_DOING', 'UPDATEING', 'DELETEING'].includes(
                          item.syncStatus
                        )
                      "
                    >
                      <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
                      <span>{{
                        item?.syncStatus === 'DELETEING'
                          ? t('report.deleting')
                          : item?.syncStatus === 'UPDATEING'
                            ? t('common.updating')
                            : t('common.syncing')
                      }}</span>
                    </div>
                    <div
                      v-else-if="
                        ['FAILED', 'INVALID'].includes(item.syncStatus) && !item?.thirdPartyOpenAuthData?.status
                      "
                    >
                      <SvgIcon name="warning" width="14" height="14" />
                      {{ t('common.syncFailed') }}
                    </div>
                    <div v-else>
                      <template v-if="['CSV_BALANCE', 'CSV', 'OPENAPI'].includes(item.type)">
                        {{ t('project.nav.transactions') }}:<span> {{ formatNumber(item.transactionCount) }}</span>
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            find(item?.platform?.platformProviderMapList, { providerId: item.providerId })
                              ?.withTransaction
                          "
                        >
                          <b
                            >txns:<span>&nbsp;{{ formatNumber(item.transactionCount) }}</span></b
                          >
                        </template>

                        Bals:<span>&nbsp;{{ formatNumber(item.balanceCount) }}</span>
                      </template>
                      <p>
                        {{ true ? t('common.updatedOn') : t('common.syncedOn') }}:
                        {{ dayjs.utc(item.updatedAt).format('YYYY/MM/DD') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="elv-account-drawer-source-item-operating">
                <SvgIcon
                  v-if="!['CSV', 'CSV_BALANCE', 'OPENAPI'].includes(item.type)"
                  name="sources-edit"
                  width="14"
                  height="14"
                  @click="onEditSource(item)"
                />
                <SvgIcon
                  name="sources-delete"
                  width="14"
                  height="14"
                  :class="{ 'is-disabled': item?.syncStatus === 'DELETEING' }"
                  @click="onDeleteSource(item)"
                />
              </div>
            </li>
          </ul>
          <div v-if="accountType.length" class="elv-account-drawer-source-add">
            <el-popover
              ref="addSourcePopoverRef"
              trigger="click"
              placement="bottom-start"
              popper-class="elv-account-drawer-source-add-popover"
              :offset="2"
            >
              <template #reference>
                <div><SvgIcon width="24" height="24" name="add-reports" />{{ t('button.addData') }}</div>
              </template>
              <div
                v-for="(item, index) in accountType"
                :key="index"
                class="elv-account-source-type-select__item"
                @click="onSelectedSourceType(item.value, item?.provider, item?.authType)"
              >
                <img :src="item?.provider?.logo" :alt="item?.provider?.name" />
                {{ item?.provider?.name }}
                <span>{{ platformProviderInfo(item).value }}</span>
              </div>
            </el-popover>
          </div>
        </div>

        <div v-if="currentAccountData?.changeAddressList?.length" class="elv-account-drawer-addresses">
          <div class="elv-account-drawer-addresses-title">
            {{ t('label.addresses') }}&nbsp;({{ currentAccountData?.changeAddressList?.length ?? 0 }})
          </div>
          <ul class="elv-account-drawer-addresses-list">
            <li v-for="(item, index) in changeAddressList" :key="index" class="elv-account-drawer-addresses-item">
              <span>{{ index + 1 }}</span>
              <p>{{ item.length > 50 ? middleEllipsis(item, 46, -4) : item }}</p>
              <SvgIcon name="sources-copy" width="10" height="10" @click="onClickedCopy(item)" />
            </li>
          </ul>
          <div
            v-if="changeAddressList.length < currentAccountData?.changeAddressList?.length"
            class="elv-account-drawer-addresses-more"
          >
            <div @click="onLoadMoreAddress">
              <SvgIcon name="events-arrow" width="14" height="14" fill="#838D95" />{{ t('button.loadMore') }}
            </div>
          </div>
        </div>
        <div
          v-if="currentAccountData?.entityAccountTagList?.length || currentAccountData?.memo"
          class="elv-account-drawer-memo"
        >
          <div class="elv-account-drawer-memo-title">{{ t('report.memo') }}</div>
          <div class="elv-account-drawer-memo-info">{{ currentAccountData?.memo }}</div>
        </div>
      </el-scrollbar>
    </div>
  </el-drawer>
  <SourceDialog
    ref="addSourceDialogRef"
    :type="sourceType"
    :model="sourceDialogType"
    :currentData="currentSourceData"
    :thirdPartyOpenAuthTypeData="thirdPartyOpenAuthTypeData"
    @updateAccountDetail="updateAccountDetail"
  />

  <AccountDialog ref="accountDialogRef" model="edit" :currentData="currentAccountData" @resetDetail="resetDetail" />

  <AddBalanceDialog ref="addBalanceDialogRef" :currentData="currentAccountData" @resetDetail="resetDetail" />

  <DeleteSource ref="deleteSourceRef" type="account" :itemData="currentSourceData" @onUpdateSourceList="resetDetail" />

  <SourceAuthDialog
    v-model:show="showSourceAuthDialog"
    :title="`Auth ${currentSourceData.provider?.name?.split(' (Default)')?.[0]}`"
    :thirdPartyOpenAuthTypeData="thirdPartyOpenAuthTypeData"
  />

  <DeleteAccount
    ref="deleteAccountRef"
    :isCanDelete="deleteAccountStatus"
    :delCountName="deleteAccountName"
    @onDelConfirmEvent="onDelConfirmEvent"
    @onDelCancelEvent="onDelCancelEvent"
  />

  <LimitReachedDialog ref="limitReachedDialogRef" @onConfirmUpgradeProjectPlan="onConfirmUpgradeProjectPlan" />
  <UpgradeProjectPlanDialog ref="upgradeProjectPlanDialogRef" />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import AccountsApi from '@/api/AccountsApi'
import AccountDialog from './AccountDialog.vue'
import DeleteAccount from './DeleteAccount.vue'
import AddBalanceDialog from './AddBalanceDialog.vue'
import defaultImg from '@/assets/img/default-logo.png'
import { ProjectSubscribeStatus } from '#/ProjectTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { ThirdPartyOpenAuthType } from '#/AccountsTypes'
import { useGlobalStore } from '@/stores/modules/global'
import { useComputedHook } from '@/hooks/useComputedHook'
import { useProjectStore } from '@/stores/modules/project'
import { useAccountStore } from '@/stores/modules/accounts'
import { isEmpty, forEach, find, cloneDeep } from 'lodash-es'
import SourceDialog from '../../components/SourceDialog.vue'
import DeleteSource from '../../components/DeleteSource.vue'
import TextIconSelect from '@/components/Base/TextIconSelect.vue'
import SourceAuthDialog from '../../components/SourceAuthDialog.vue'
import { middleEllipsis, formatNumber, formatInputAddress } from '@/lib/utils'
import LimitReachedDialog from '@/pages/Financials/components/LimitReachedDialog.vue'
import UpgradeProjectPlanDialog from '@/pages/Financials/ProjectSetting/components/UpgradeProjectPlanDialog.vue'

const props = defineProps({
  tableType: {
    type: String,
    default: 'account' // account || transaction
  },
  drawerData: {
    type: Object,
    required: true
  }
})

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const addSourceDialogRef = useTemplateRef('addSourceDialogRef')
const currentAccountData: any = ref({})
const { toClipboard } = useClipboard()

const globalStore = useGlobalStore()
const entityStore = useEntityStore()
const projectStore = useProjectStore()
const accountStore = useAccountStore()
const fromData = reactive({
  name: '',
  role: '',
  memo: ''
})
const addressParams = reactive({
  page: 1,
  limit: 10
})
const deleteSourceRef = useTemplateRef('deleteSourceRef')
const accountDialogRef = useTemplateRef('accountDialogRef')
const addBalanceDialogRef = useTemplateRef('addBalanceDialogRef')
const addSourcePopoverRef = useTemplateRef('addSourcePopoverRef')
const addBalancePopoverRef = useTemplateRef('addBalancePopoverRef')
const deleteAccountRef = useTemplateRef('deleteAccountRef')
const isShowViewDrawer = ref(false)
const loading = ref(false)
const sourceType = ref('')
const authLoading = ref(false)
const showSourceAuthDialog = ref(false)
const sourceDialogType = ref('add')
const currentSourceData: any = ref({})
const emit = defineEmits(['resetList', 'onUpdateAccountOrContact'])
const balanceLoading = ref(false)
const deleteAccountStatus = ref(false)
const deleteAccountName = ref('')
const limitReachedDialogRef = useTemplateRef('limitReachedDialogRef')
const upgradeProjectPlanDialogRef = useTemplateRef('upgradeProjectPlanDialogRef')

const thirdPartyOpenAuthTypeData = ref<ThirdPartyOpenAuthType>({
  thirdPartyOpenAuthDataId: '',
  type: '',
  status: '',
  generationTime: '',
  extra: {
    link_url: ''
  }
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const projectDetail = computed(() => {
  if (!isEmpty(projectStore.projectDetail)) return projectStore.projectDetail
  return globalStore.projectDetail(entityStore.entityDetail?.projectId)
})

const currentPlatform = computed(() => {
  const platform = cloneDeep(
    currentAccountData.value?.platform?.platformProviderMapList
      ? currentAccountData.value?.platform
      : (find(entityStore.platformList, { platformId: currentAccountData.value?.platformId }) ?? {})
  )
  const platformProviderMapList = platform?.platformProviderMapList
  platformProviderMapList?.forEach((item: any) => {
    if (item.isDefault) item.provider.name = `${item.provider.name} (Default)`
  })
  platform.platformProviderMapList = platformProviderMapList
  return platform
})

const changeAddressList = computed(() => {
  if (currentAccountData.value?.changeAddressList?.length) {
    return currentAccountData.value?.changeAddressList.slice(0, addressParams.page * addressParams.limit)
  }
  return []
})

const accountType = computed(() => {
  let addSourceOptions: any = []

  if (currentAccountData.value?.platform?.collectorDriver !== 'NONE') {
    addSourceOptions = currentPlatform.value?.platformProviderMapList?.filter(
      (item: any) =>
        item.provider.slug === 'csv' ||
        isEmpty(find(currentAccountData.value?.sourceList, { providerId: item.providerId }))
    )
  } else {
    addSourceOptions = currentPlatform.value?.platformProviderMapList?.filter(
      (item: any) => item.provider.slug === 'csv'
    )
  }
  if (addSourceOptions?.length) {
    addSourceOptions.forEach((item: any) => {
      item.value = item.provider.slug === 'csv' ? 'CSV' : currentAccountData.value?.platform?.type
    })
  }
  return addSourceOptions ?? []
})

const platformProviderInfo: any = useComputedHook((platformProvider: any) => {
  let info = ''
  if (platformProvider.withBalance && platformProvider.withTransaction) {
    info = 'Transaction and Balance'
  } else if (platformProvider.withBalance) {
    info = 'Balance Only'
  } else if (platformProvider.provider.slug === 'csv' || platformProvider.withTransaction) {
    info = 'Transaction Only'
  }
  return info
})

const currentProviderId = ref(currentAccountData.value.usedProviderId)

const computedOptions = computed(() => {
  const providerList = currentAccountData.value.selectProviderList || []
  return providerList.map((item: any) => {
    return {
      value: item.providerId,
      label: item.name,
      icon: item.logo
    }
  })
})

const isCanDelAccount = computed(() => {
  return (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.delete
  )
})

const currentProvider = computed(() => {
  return (currentAccountData.value.selectProviderList || []).find(
    (item: any) => item.providerId === currentProviderId.value
  )
})

const tooltip = computed(() => {
  switch (currentProvider.value?.slug) {
    case 'calculated':
      return t('report.calculatedBalance')
    default:
      return t('report.syncedBalance', { source: currentProvider.value?.name })
  }
})

const onConfirmUpgradeProjectPlan = () => {
  upgradeProjectPlanDialogRef.value?.changeUpgradeProjectDialogStatus()
}

const onClickedCopy = async (address: string) => {
  try {
    await toClipboard(address)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}

const onLoadMoreAddress = () => {
  addressParams.page += 1
}

const onSelectChange = async (value: any) => {
  try {
    balanceLoading.value = true
    const result = await accountStore.fetchEntityAccountProviderBalance(
      entityId.value,
      currentAccountData.value.entityAccountId,
      value
    )
    const newTreasuryBalanceList: any[] = []
    forEach(currentAccountData.value.treasuryBalanceList, (item) => {
      const resultBalance = find(result.data, { currency: item.currency })
      newTreasuryBalanceList.push({
        ...item,
        amount: resultBalance?.amount || resultBalance?.balance || 0,
        amountFC: resultBalance?.amountFC || resultBalance?.balanceFC || 0,
        failCount: resultBalance?.failCount || 0
      })
    })

    currentAccountData.value.treasuryBalanceList = newTreasuryBalanceList
  } catch (error) {
    console.error(error)
  } finally {
    balanceLoading.value = false
  }
}

const fetchSourceAuthDetail = async () => {
  try {
    authLoading.value = true
    const { data } = await AccountsApi.getSourceAuthDetail(
      entityId.value,
      thirdPartyOpenAuthTypeData.value.thirdPartyOpenAuthDataId
    )
    thirdPartyOpenAuthTypeData.value.status = data.status
    if (data.status === 'COMPLETED') {
      ElMessage.success('Authenticate successfully')
      if (sourceDialogType.value === 'add') {
        addSourceDialogRef.value?.onCheckSourceDialog()
      } else {
        loading.value = true
        await AccountsApi.editAccountSource(entityId.value, currentSourceData.value?.sourceId, {
          name: currentSourceData.value.name,
          thirdPartyOpenAuthDataId: thirdPartyOpenAuthTypeData.value.thirdPartyOpenAuthDataId
        })
        // eslint-disable-next-line no-use-before-define
        resetDetail()
      }
    } else {
      ElMessage.error('Authenticate failed')
    }
  } catch (error) {
    console.log(error)
  } finally {
    authLoading.value = false
    loading.value = false
  }
}

const receiveMessage = (message: any) => {
  if (message.data === 'close') {
    showSourceAuthDialog.value = false
    authLoading.value = false
    window.removeEventListener('message', receiveMessage)
    fetchSourceAuthDetail()
  }
  if (message.data === 'success') {
    authLoading.value = false
    showSourceAuthDialog.value = false
    window.removeEventListener('message', receiveMessage)
    fetchSourceAuthDetail()
  }
}

const applyOpenAuthInfo = async (authType: string) => {
  try {
    loading.value = true
    if (
      !thirdPartyOpenAuthTypeData.value.thirdPartyOpenAuthDataId ||
      thirdPartyOpenAuthTypeData.value.status !== 'COMPLETE'
    ) {
      const { data } = await AccountsApi.applySourceAuth(entityId.value, {
        type: authType
      })
      thirdPartyOpenAuthTypeData.value = data
    }
    showSourceAuthDialog.value = true
    nextTick(() => {
      window.addEventListener('message', receiveMessage, false)
    })
  } catch (error: any) {
    console.log(error)
  }
}

const onReAuthenticate = (item: any) => {
  loading.value = true
  sourceDialogType.value = 'edit'
  currentSourceData.value = item
  applyOpenAuthInfo(item.thirdPartyOpenAuthData.type)
}

const onSelectedSourceType = (type: string, provider: any, authType: string | null) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.dataSource?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (
    projectDetail.value?.subscribeStatus === ProjectSubscribeStatus.EXCEEDED ||
    projectDetail.value?.txCountStatus === ProjectSubscribeStatus.EXCEEDED
  ) {
    limitReachedDialogRef.value?.changeLimitReachedDialogStatus()
    return
  }
  sourceType.value = type
  sourceDialogType.value = 'add'
  currentSourceData.value.entityAccountId = currentAccountData.value.entityAccountId
  currentSourceData.value.platform = currentAccountData.value?.platform
  currentSourceData.value.provider = provider
  addSourcePopoverRef.value?.hide()
  if (authType !== null) {
    applyOpenAuthInfo(authType)
  } else {
    addSourceDialogRef.value?.onCheckSourceDialog()
  }
}

const onEditSource = (source: object) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.dataSource?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentSourceData.value = source
  currentSourceData.value.entityAccountId = currentAccountData.value.entityAccountId
  sourceType.value = props.drawerData?.platform?.type
  sourceDialogType.value = 'edit'
  addSourcePopoverRef.value?.hide()
  addSourceDialogRef.value?.onCheckSourceDialog()
}

const onDeleteSource = (source: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.dataSource?.delete
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }

  if (source?.syncStatus === 'DELETEING') {
    return
  }

  currentSourceData.value = source
  currentSourceData.value.entityAccountId = currentAccountData.value.entityAccountId
  addSourcePopoverRef.value?.hide()
  deleteSourceRef.value?.onCheckDeleteDialog()
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const onEditAccount = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  accountDialogRef.value?.onCheckAccountDialog()
}

const onSelectedBalance = (item: any, index: number) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.balanceAdd
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentAccountData.value.currency = item?.currency
  currentAccountData.value.underlyingCurrency = item?.underlyingCurrency
  addBalancePopoverRef.value?.[index]?.hide()
  addBalanceDialogRef.value?.onCheckAccountBalanceDialog()
}

const onDeleteAccount = async () => {
  if (isCanDelAccount.value) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  try {
    isShowViewDrawer.value = false
    deleteAccountName.value = currentAccountData.value.name || ''
    deleteAccountRef.value?.changeDelDialogStatus(true)
    const { data } = await AccountsApi.checkCanBeDeleted(entityId.value, currentAccountData.value.entityAccountId)
    deleteAccountStatus.value = !data.hasMatchedTransaction
  } catch (error: any) {
    ElMessage.error(error?.messge || 'Delete failed')
    console.log(error)
  } finally {
    deleteAccountRef.value?.changeLoadingStatus(false)
  }
}

const onDelCancelEvent = () => {
  deleteAccountRef.value?.closeDeleteDialog()
}

const onDelConfirmEvent = async () => {
  if (!deleteAccountStatus.value) {
    deleteAccountRef.value?.closeDeleteDialog()
    return
  }
  try {
    await AccountsApi.deleteAccount(entityId.value, currentAccountData.value.entityAccountId)
    emit('resetList')
    ElMessage.success(t('message.deleteSuccess'))
  } catch (error: any) {
    ElMessage.error(error?.messge || 'Delete failed')
  } finally {
    deleteAccountRef.value?.closeDeleteDialog()
  }
}

const onCheckDrawerStatus = () => {
  isShowViewDrawer.value = !isShowViewDrawer.value
  if (!isShowViewDrawer.value) {
    fromData.name = ''
    addressParams.page = 1
    addressParams.limit = 10
    currentAccountData.value = {}
  }
  thirdPartyOpenAuthTypeData.value = {
    thirdPartyOpenAuthDataId: '',
    type: '',
    status: '',
    generationTime: '',
    extra: {
      link_url: ''
    }
  }
}

const fetchEntityAccountDetail = async () => {
  try {
    loading.value = true
    const { data } = await AccountsApi.getEntityAccountDetail(
      entityId.value,
      props.drawerData?.entityAccount?.entityAccountId
    )
    currentAccountData.value = data
    currentProviderId.value = currentAccountData.value.usedProviderId
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const resetDetail = () => {
  fetchEntityAccountDetail()
}

const updateAccountDetail = () => {
  if (addSourceDialogRef.value?.showSourceDialog) {
    addSourceDialogRef.value?.onCheckSourceDialog()
  }
  fetchEntityAccountDetail()
}

defineExpose({ onCheckDrawerStatus, isShowViewDrawer, updateAccountDetail })

watch(
  [() => props.drawerData, () => isShowViewDrawer.value],
  async () => {
    if (isShowViewDrawer.value && !isEmpty(props.drawerData)) {
      fetchEntityAccountDetail()
    }
  },
  { flush: 'post' }
)
</script>

<style lang="scss" scoped>
.elv-account-drawer {
  .elv-account-drawer-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;
  }

  .elv-account-drawer-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-account-drawer {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 18px 0px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 50px;
  }

  .elv-account-drawer-content-header,
  .elv-account-drawer-content-body__wrap {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
  }

  .elv-account-drawer-content-header {
    margin-bottom: 16px;
  }

  .elv-account-drawer-content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .elv-account-drawer-content-title__currency {
      display: flex;

      img {
        width: 16px;
        height: 16px;
        display: block;
        border-radius: 50%;
      }

      .elv-account-drawer-currency__name {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #838d95;
        margin-left: 6px;
        margin-right: 8px;
        padding-right: 8px;
      }
    }

    .elv-account-drawer-content-title__operating {
      display: flex;

      .el-button {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
        cursor: pointer;

        svg {
          color: #636b75;
          margin-right: 6px;
        }

        &:not(.is-disabled):hover,
        &:not(.is-disabled):focus,
        &:not(.is-disabled):active {
          background-color: #fff;
          color: #1343bf;

          svg {
            fill: #1343bf;
          }
        }
      }

      .elv-account-drawer-content__delete {
        cursor: pointer;
        margin-left: 8px;
        padding-left: 8px;
        height: 16px;
        display: flex;
        align-items: center;
        border-left: 1px solid #dde1e6;

        &.is-disabled {
          cursor: not-allowed;
          fill: #c4c9d1;
        }

        &:not(.is-disabled):hover {
          svg {
            fill: #1343bf;
          }
        }

        svg {
          color: #636b75;
        }
      }
    }
  }

  .elv-account-drawer-content-info {
    padding-left: 22px;
    padding-bottom: 16px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-drawer-content-name {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .elv-account-drawer-content-paymentIdentifier {
      display: flex;
      align-items: center;
      margin-top: 12px;

      p {
        font-family: 'Barlow';
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        color: #838d95;
        margin-right: 4px;
      }

      svg {
        cursor: pointer;
      }
    }
  }

  .elv-account-drawer-balance {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;
    margin-bottom: 16px;

    .elv-account-drawer-balance-title {
      padding: 6px 0px 14px;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      border-bottom: 1px solid #edf0f3;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;

      .el-select__wrapper {
        background: #f9fafb;
      }
    }

    .elv-account-drawer-balance-list {
      margin-bottom: 8px;

      .elv-account-drawer-balance-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #edf0f3;

        > div {
          display: flex;
          align-items: center;

          svg {
            margin-right: 8px;
          }

          img {
            width: 24px;
            height: 24px;
            display: block;
            border-radius: 50%;
            margin-right: 8px;
          }

          .elv-account-drawer-balance-item__name {
            color: #1e2024;
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .elv-account-drawer-balance-item__count {
            color: #838d95;
            margin-top: 2px;
            font-family: 'Plus Jakarta Sans';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            div {
              display: flex;

              p {
                display: block;
                margin-left: 8px;
                padding-left: 8px;
                border-left: 1px solid #dde1e6;
              }
            }

            img {
              display: block;
              margin-right: 4px;
              width: 14px;
              height: 14px;
              animation: loading-rotate 2s linear infinite;
            }
          }
        }
      }

      .elv-account-drawer-balance-item-operating {
        display: flex;

        div {
          color: #1e2024;
          font-family: 'Barlow';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .elv-account-drawer-balance-item-verify {
          margin-left: 4px;

          img {
            display: block;
            width: 14px;
            height: 14px;
            margin-right: 0px;
          }
        }

        svg {
          fill: #838d95;
          cursor: pointer;
          margin-right: 8px;

          &:hover {
            fill: #1343bf;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .elv-account-drawer-balance-empty {
      display: flex;
      padding: 8px 0px;
      height: 55px;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      color: #aaafb6;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .elv-account-drawer-balance-add {
      padding: 8px 0px;
      display: flex;
      margin-left: 16px;

      .el-tooltip__trigger {
        color: #2f63eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        cursor: pointer;
        align-items: center;

        svg {
          fill: #838d95;
          margin-right: 8px;
        }
      }
    }
  }

  .elv-account-drawer-source {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;
    margin: 16px 0;

    .elv-account-drawer-source-title {
      padding: 6px 0px 14px;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      border-bottom: 1px solid #edf0f3;
      margin-bottom: 8px;
    }

    .elv-account-drawer-source-list {
      margin-bottom: 8px;

      .elv-account-drawer-source-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #edf0f3;

        > div {
          display: flex;
          align-items: center;

          svg {
            margin-right: 8px;
          }

          img {
            width: 24px;
            height: 24px;
            display: block;
            margin-right: 8px;
          }

          .elv-account-drawer-source-item__name {
            color: #1e2024;
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .elv-account-drawer-source-item__auth {
            display: flex;
            color: #eb3a30;
            font-family: 'Plus Jakarta Sans';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 2px 0;

            svg {
              margin-right: 4px;
            }

            span {
              text-decoration-line: underline;
              cursor: pointer;
            }
          }

          .elv-account-drawer-source-item__count {
            color: #838d95;
            margin-top: 2px;
            font-family: 'Plus Jakarta Sans';
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            div {
              display: flex;

              svg {
                margin-right: 4px;
              }

              p {
                display: block;
                margin-left: 8px;
                padding-left: 8px;
                border-left: 1px solid #dde1e6;
              }

              b {
                font-weight: 400;
                border-right: 1px solid #dde1e6;
                padding-right: 8px;
                margin-right: 8px;
              }
            }

            img {
              display: block;
              margin-right: 4px;
              width: 14px;
              height: 14px;
              animation: loading-rotate 2s linear infinite;
            }
          }
        }
      }

      .elv-account-drawer-source-item-operating {
        display: flex;

        svg {
          fill: #636b75;
          cursor: pointer;
          margin-right: 8px;

          &:not(.is-disabled):hover {
            fill: #1343bf;
          }

          &.is-disabled {
            cursor: not-allowed !important;
            fill: #c4c9d1;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .elv-account-drawer-source-add {
      padding: 8px 0px;
      display: flex;

      .el-tooltip__trigger {
        color: #2f63eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        cursor: pointer;
        align-items: center;

        svg {
          fill: #7596eb;
          margin-right: 8px;
        }
      }
    }
  }

  .elv-account-drawer-addresses {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;
    margin: 16px 0;

    .elv-account-drawer-addresses-title {
      padding: 6px 0px 14px;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      border-bottom: 1px solid #edf0f3;
      margin-bottom: 8px;
    }

    .elv-account-drawer-addresses-list {
      .elv-account-drawer-addresses-item {
        display: flex;
        align-items: center;
        padding: 8px 0;
        border-bottom: 1px solid #edf0f3;
        gap: 8px;

        span {
          width: 16px;
          color: #aaafb6;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16.5px;
        }

        p {
          color: #1e2024;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 19.5px;
          max-width: 340px;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
        }

        svg {
          width: 10px;
          height: 10px;
          cursor: pointer;
        }
      }
    }

    .elv-account-drawer-addresses-more {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;

      div {
        display: flex;
        height: 30px;
        padding: 0px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        border-radius: 22px;
        background: #fff;

        svg {
          transform: rotate(90deg);
        }

        &:hover {
          background-color: #edf0f3;
          color: #0e0f11;

          svg {
            fill: #636b75;
          }
        }
      }
    }
  }

  .elv-account-drawer-memo {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;

    .elv-account-drawer-memo-title {
      padding: 6px 0px 14px;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      border-bottom: 1px solid #edf0f3;
      margin-bottom: 8px;
    }

    .elv-account-drawer-tag-list {
      padding-bottom: 16px;
      display: flex;

      .elv-account-drawer-tag-item {
        display: flex;
        height: 24px;
        box-sizing: border-box;
        padding: 0px 16px;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        border: 1px solid #d0d4d9;
        color: #636b75;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 8px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    .elv-account-drawer-memo-info {
      color: #000;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding-bottom: 8px;
    }
  }
}

.el-popper.elv-account-drawer-balance-tips {
  padding: 5px 10px 8px 10px;
  width: 200px;
  box-sizing: border-box;
}

.el-popper.elv-account-drawer-source-add-popover {
  padding: 0px;
  min-width: 240px;
  width: fit-content !important;

  .elv-account-source-type-select__item {
    display: flex;
    height: 32px;
    padding: 0px 8px;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: background 0.1s;
    white-space: nowrap;

    img {
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 25px;
    }

    span {
      display: flex;
      padding: 6px 8px;
      border-radius: 14px;
      background: #f9fafb;
      align-items: center;
      gap: 10px;
      color: #aaafb6;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Barlow';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
    }

    &:hover {
      background: #f9fafb;
    }
  }
}

.el-popper.elv-account-drawer-balance-add-popover {
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;

  .elv-account-balance-type-select__item {
    display: flex;
    height: 40px;
    width: 100%;
    padding: 0px 12px;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: background 0.1s;

    &:hover {
      background: #f9fafb;
    }
  }
}

.elv-account-drawer-purpose-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 4px 0;
  }

  .el-select-dropdown__item {
    height: 32px;
    width: 124px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #394048;

    &.hover {
      background: #f9fafb;
    }
  }
}

.elv-account-drawer-contact-select {
  width: 100%;
  margin-bottom: 16px;

  .el-input__wrapper {
    padding: 12px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    &:hover {
      border: 1px solid #5e85eb;
      box-shadow: none;
    }

    .el-input__prefix-inner > :last-child {
      margin-right: 10px;
    }
  }
}
</style>

#f9fafb
